import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "8a471573-af6b-4624-8d54-21a9dac12a01"
// title: ""
// type: :html
// key: "body"
// parent_id: "50938e30-6916-4ef2-99ea-4371a144f75a"
export function Body8a471573Af6b46248d5421a9dac12a01(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} Body8a471573Af6b46248d5421a9dac12a01`}>
      {props["join-omen"] || <JoinOmen053e6adb3cd1428bB6890b096cb29716 />}
      {props["omen-image-gallery"] || <OmenImageGallery03ef9469206b419c8f82Ea74b0ef5d8a />}
      {props["our-story"] || <OurStoryF01cdb47543345c399ef5d9a33a2989a />}
      {props["more-questions"] || <MoreQuestionsC71fc2963a8e4cd2A4832f09d8276274 />}
    </section>
  );
}

// id: "c997a96d-30ed-41c5-a084-fe792906df67"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterC997a96d30ed41c5A084Fe792906df67(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterC997a96d30ed41c5A084Fe792906df67 footer`}>
      <div className="logo-wrapper">
        {props["logo"] || <Logo89080c8d27da4b37Bf9b633028d554bd />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright4f392a08604546e488d689e2daca4c88", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowE54b3b6aA7014664B695D5d394f61e06", parentTag)} {...props} />}
    </div>
  );
}

// id: "f01cdb47-5433-45c3-99ef-5d9a33a2989a"
// title: "our-story"
// type: :html
// key: "our-story"
// parent_id: "8a471573-af6b-4624-8d54-21a9dac12a01"
export function OurStoryF01cdb47543345c399ef5d9a33a2989a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} OurStoryF01cdb47543345c399ef5d9a33a2989a`}>
      <div className="our-story-main-container">
        <div className="background-container">
          <div className="content-container">
            <div className="text">
              <h1>
                Our Story
              </h1>
              <p>
                The idea for a men’s naturist group in Portland, Oregon was born in the mind of Jim F. Jim,
                having
                been an avid naturist for many years and a member of GNI (Gay Naturists International).
              </p>
              <a href="/about">
                Learn More &gt;
              </a>
            </div>
            <div className="image">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/VsecOwm1SXEF1_1B_regular.jpeg" alt="Group Photo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "053e6adb-3cd1-428b-b689-0b096cb29716"
// title: "join-omen"
// type: :html
// key: "join-omen"
// parent_id: "8a471573-af6b-4624-8d54-21a9dac12a01"
export function JoinOmen053e6adb3cd1428bB6890b096cb29716(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} JoinOmen053e6adb3cd1428bB6890b096cb29716`}>
      <div className="omen-container-main">
        <h1 className="omen-container-heading">
          Why Join OMEN?
        </h1>
        <div className="omen-grid-container">
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/1OEb72KdzLf1GZsp_file.svg" alt="Icon 1" />
            </div>
            <div>
              <h3>
                Private Group Of Oregon Men Enjoying Naturism
              </h3>
              <p>
                Connect with other friendly naked men via our various organized activities and form great camaraderie.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/ygyvuTtS_-VOoNM2_file.svg" alt="Icon 2" />
            </div>
            <div>
              <h3>
                Inclusivity
              </h3>
              <p>
                Open to age-appropriate gay/bi, and trans men of all races, colors, religions, physical disabilities, and ethnic or national origins.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/xWQBrnOuGSsf8rnm_file.svg" alt="Icon 3" />
            </div>
            <div>
              <h3>
                Monthly Activities And Events
              </h3>
              <p>
                Each month we offer social potlucks, dances, karaoke nights, pool parties, spa nights, camping weekends, themed events, and much more.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/8FANX31Ya6YXkRRv_file.svg" alt="Icon 4" />
            </div>
            <div>
              <h3>
                No Shaming Policy
              </h3>
              <p>
                We&#39;re regular men, not models and whatever your issue may be, someone else will be larger, smaller, balder, so just be yourself and join us!
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/L5Igdk4e3i6zUwUg_file.svg" alt="Icon 5" />
            </div>
            <div>
              <h3>
                Exclusive Discounts
              </h3>
              <p>
                Save money on spa nights, food, and beverages from our recommended partners and get access to exclusive offers.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/lW595gIuA-dC63tv_file.svg" alt="Icon 6" />
            </div>
            <div>
              <h3>
                Member Driven Organization
              </h3>
              <p>
                We are not a business venture. Most of our gatherings are in private residences and camping on private, member-owned land.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "03ef9469-206b-419c-8f82-ea74b0ef5d8a"
// title: "omen-image-gallery"
// type: :html
// key: "omen-image-gallery"
// parent_id: "8a471573-af6b-4624-8d54-21a9dac12a01"
export function OmenImageGallery03ef9469206b419c8f82Ea74b0ef5d8a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} OmenImageGallery03ef9469206b419c8f82Ea74b0ef5d8a omen-image-main-container`}>
      <div className="omen-image-inner-container">
        <div>
          <h1>
            Images From Past Events
          </h1>
        </div>
        <div className="omen-gallery">
          <div className="omen-gallery-fw-img">
            <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/m3EBzoFl-lnsTJiv_regular.jpeg" alt="Image 1" />
          </div>
          <div className="inner-gallery-container">
            <div className="inner-gallery-left-container">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/QY4rSV-Bl1yr9eWn_regular.jpeg" alt="Image 2" />
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/V6el6MEvsG8P210p_regular.jpeg" alt="Image 3" />
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/t648HTYQLalFs0uA_regular.jpeg" alt="Image 4" />
            </div>
            <div className="inner-gallery-right-container">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/FiJKcorx594UcY9j_regular.jpeg" alt="Image 5" />
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/ipHpKSVapJPjM3fV_regular.jpeg" alt="Image 6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// id: "50938e30-6916-4ef2-99ea-4371a144f75a"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage50938e3069164ef299ea4371a144f75a(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage50938e3069164ef299ea4371a144f75a page`}>
      {props["header"] || <HomeHeader27f336d65a7242729c534ef2443f7edd parentTag="Header50e78e5669dd45dc8669654b4a00f5c0" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack325f00c6D48748e9B798C7c85c66d1a1", parentTag)} {...props} />}
      {props["body"] || <Body8a471573Af6b46248d5421a9dac12a01 />}
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer35dc48cf5f23469aA67c962905789fa9" {...props} />}
    </div>
  );
}

// id: "c71fc296-3a8e-4cd2-a483-2f09d8276274"
// title: "more-questions"
// type: :html
// key: "more-questions"
// parent_id: "8a471573-af6b-4624-8d54-21a9dac12a01"
export function MoreQuestionsC71fc2963a8e4cd2A4832f09d8276274(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} MoreQuestionsC71fc2963a8e4cd2A4832f09d8276274 faq-section`}>
      <div className="faq-main-container">
        <h2>
          FAQs
        </h2>
        <h3>
          Have More Questions?
        </h3>
        <details>
          <summary>
            How Big Is The Community?
          </summary>
          <p>
            The OMEN community has over 260 active members and we are growing each month.
          </p>
        </details>
        <details>
          <summary>
            Where Are The Members Located?
          </summary>
          <p>
            Our members are located in Oregon, Washington and many other states.
          </p>
        </details>
        <details>
          <summary>
            Who Should Not Apply?
          </summary>
          <p>
            All our events are completely naked. We are not a clothing optional group, and if you are uncomfortable with this you should not apply.
          </p>
        </details>
        <details>
          <summary>
            Is There An Age Limit?
          </summary>
          <p>
            Members must be at least 21 years old to join OMEN.
          </p>
        </details>
        <details>
          <summary>
            What Kind Of Discounts Are Available?
          </summary>
          <p>
            We offer a variety of discounts to our members from our partners in the Portland Vancouver metro area.
          </p>
        </details>
        <details>
          <summary>
            Can I Host An Event?
          </summary>
          <p>
            Yes, members can apply and request to host nude events after fulfilling certain criteria.
          </p>
        </details>
        <div className="buttons">
          <a href="/join">
            <button className="member-btn" onclick="window.location.href=&#39;#&#39;">
              Become A Member
            </button>
          </a>
          <a href="/events/public">
            <button className="events-btn" onclick="window.location.href=&#39;#&#39;">
              View Upcoming Events
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

// id: "d924ad7c-e14e-4b93-a665-c9e008a77f50"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesD924ad7cE14e4b93A665C9e008a77f50(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesD924ad7cE14e4b93A665C9e008a77f50`}>
      {props.children}
    </div>
  );
}

// id: "923c5d71-63a1-475c-a43e-528a65c1b948"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header923c5d7163a1475cA43e528a65c1b948(props: any) {
  return (
    <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header923c5d7163a1475cA43e528a65c1b948" {...props} />
  );
}

// id: "89080c8d-27da-4b37-bf9b-633028d554bd"
// title: ""
// type: :html
// key: "logo"
// parent_id: "c997a96d-30ed-41c5-a084-fe792906df67"
export function Logo89080c8d27da4b37Bf9b633028d554bd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qD9ySvYVxmpqtVP1_regular.png" alt="Demo 1 logo" className={`${parentTag || ""} Logo89080c8d27da4b37Bf9b633028d554bd logo`} />
  );
}

// id: "7d402c8c-e281-4bbb-bd82-5159d5470d33"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "d8b34055-4a1a-4fe6-93d4-9af4adfad23b"
export function LinkedLogo7d402c8cE2814bbbBd825159d5470d33(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo7d402c8cE2814bbbBd825159d5470d33", parentTag)} label={<DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label143d2a18D4e64e79A0e8C2562c7db9bd" alt="logo" />} {...props} />
  );
}

// id: "9a7762a5-29b1-4c70-aa02-b4ace1c41133"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "74f7039e-8871-42b5-a8b1-de77da9afd66"
export const NavMenuSlug9a7762a529b14c70Aa02B4ace1c41133 = "members-primary-nav";

// id: "9f20e724-8b18-4938-a1ca-602176ef20ee"
// title: ""
// type: :text
// key: "title"
// parent_id: "9eaca8c3-009d-4030-93a3-ca593e9dad87"
export const Title9f20e7248b184938A1ca602176ef20ee = "About Us";

// id: "9e11bbd4-23c4-471e-987c-d7fcf039b992"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault9e11bbd423c4471e987cD7fcf039b992(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault9e11bbd423c4471e987cD7fcf039b992 page`}>
      {props["header"] || <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header551448c87c8c4384Bd40F44b59928084" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer0e59ffee1c76494a9a31Fe35bae09665" {...props} />}
    </div>
  );
}

// id: "9c9f1243-6129-4cc3-bc47-d33471b1171e"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages9c9f124361294cc3Bc47D33471b1171e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages9c9f124361294cc3Bc47D33471b1171e`}>
      {props["header"] || <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header719db0e3Df624f8b88080b819876ba3b" {...props} />}
      {props.children}
    </div>
  );
}

// id: "184bec94-ba00-4d1d-92c0-0cb82e46512d"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "452c3fe0-cc39-4807-90b5-c9f2251bb0cd"
export function Hero184bec94Ba004d1d92c00cb82e46512d(props: any) {
  return (
    <SecondaryMarketingHero763b18d9E69141d39fd3C2d0817ce50a parentTag="Hero184bec94Ba004d1d92c00cb82e46512d" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "8ce4e9de-dbeb-4f79-9652-31f9d0e53610"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d98b0aa4-c4eb-441b-baf5-81f35ffecabf"
export function Header8ce4e9deDbeb4f79965231f9d0e53610(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header8ce4e9deDbeb4f79965231f9d0e53610" title="Public Events" {...props} />
  );
}

// id: "86e22357-5e29-4850-80f4-97bc8454e196"
// title: ""
// type: :text
// key: "label"
// parent_id: "e1c78e5a-8d9c-4b9e-a3d4-7bcbb7a5c74a"
export const Label86e223575e29485080f497bc8454e196 = "Join Us";

// id: "e2e647e1-d3e3-4162-aa23-f7cfe3cfec75"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qD9ySvYVxmpqtVP1_regular.png" className={`${parentTag || ""} DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 logo`} alt="logo" />
  );
}

// id: "452c3fe0-cc39-4807-90b5-c9f2251bb0cd"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f parentTag="HeaderTopE5b7fafdDff34845Ac707bd89a9b8ee5" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero763b18d9E69141d39fd3C2d0817ce50a parentTag="Hero184bec94Ba004d1d92c00cb82e46512d" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "94b70dac-4f5b-4842-9ec7-b011fde716c5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "54b36f6a-5457-440e-b221-1cd3a2ec7deb"
export function Footer94b70dac4f5b48429ec7B011fde716c5(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer94b70dac4f5b48429ec7B011fde716c5" {...props} />
  );
}

// id: "c0c78bd0-5069-4079-ad0c-d56f290e685f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "d98b0aa4-c4eb-441b-baf5-81f35ffecabf"
export function FooterC0c78bd050694079Ad0cD56f290e685f(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="FooterC0c78bd050694079Ad0cD56f290e685f" {...props} />
  );
}

// id: "29573296-d376-4a81-b1ce-b1f5eeb70391"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "514cc956-29c8-4f05-8c32-3f5d1ff89b29"
export const ContentSlug29573296D3764a81B1ceB1f5eeb70391 = "home-page-quote";

// id: "2fc1e3a1-000f-4c64-9064-04bf24e3fcee"
// title: ""
// type: :text
// key: "title"
// parent_id: "47d7eaf0-3a51-4430-a496-03e96b134c5e"
export const Title2fc1e3a1000f4c64906404bf24e3fcee = "Resources";

// id: "c4b4f82c-b057-4318-9f37-bdded1561641"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderC4b4f82cB05743189f37Bdded1561641(props: any) {
  return (
    <ShortHeaderD8b340554a1a4fe693d49af4adfad23b parentTag="DefaultHeaderC4b4f82cB05743189f37Bdded1561641" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "719db0e3-df62-4f8b-8808-0b819876ba3b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9c9f1243-6129-4cc3-bc47-d33471b1171e"
export function Header719db0e3Df624f8b88080b819876ba3b(props: any) {
  return (
    <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header719db0e3Df624f8b88080b819876ba3b" {...props} />
  );
}

// id: "2203d03c-45dc-442b-b3a0-428028d5495d"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "27f336d6-5a72-4272-9c53-4ef2443f7edd"
export function HeaderTop2203d03c45dc442bB3a0428028d5495d(props: any) {
  return (
    <DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f parentTag="HeaderTop2203d03c45dc442bB3a0428028d5495d" {...props} />
  );
}

// id: "562e6029-b22d-4b76-b65e-873b61cc483a"
// title: ""
// type: :reference
// key: "label"
// parent_id: "6ee00ab5-b5ca-406b-882f-aaec5c1c87c1"
export function Label562e6029B22d4b76B65e873b61cc483a(props: any) {
  return (
    <DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label562e6029B22d4b76B65e873b61cc483a" {...props} />
  );
}

// id: "0e77f299-08bd-4863-b76e-96622286193a"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage0e77f29908bd4863B76e96622286193a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage0e77f29908bd4863B76e96622286193a`}>
      {props["header"] || <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="HeaderA859c70d16a24bceB5da7fe9aa3670d3" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer90059ae219c34c1c898dDf2bec4414d2" {...props} />}
    </div>
  );
}

// id: "7843427e-9efa-4ce3-9a51-0837f9fed4fe"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards7843427e9efa4ce39a510837f9fed4fe(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards7843427e9efa4ce39a510837f9fed4fe", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "e2c42bb3-7c57-46d9-aca9-4565b08e30f7"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "c4b4f82c-b057-4318-9f37-bdded1561641"
export const NavMenuSlugE2c42bb37c5746d9Aca94565b08e30f7 = "marketing-primary-nav";

// id: "40464e23-4d8b-4124-bc20-57137ee8cfc4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "223d2a7a-dcf8-4cde-8567-b6eb368f383f"
export function Header40464e234d8b4124Bc2057137ee8cfc4(props: any) {
  return (
    <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header40464e234d8b4124Bc2057137ee8cfc4" {...props} />
  );
}

// id: "19dc2a7a-2423-4dd8-b66b-475a879cf63a"
// title: ""
// type: :text
// key: "class"
// parent_id: "6ee00ab5-b5ca-406b-882f-aaec5c1c87c1"
export const Class19dc2a7a24234dd8B66b475a879cf63a = "linked-logo";

// id: "79be24ef-a98a-4986-bd88-83b2de8a3c78"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote79be24efA98a4986Bd8883b2de8a3c78(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote79be24efA98a4986Bd8883b2de8a3c78`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "d98b0aa4-c4eb-441b-baf5-81f35ffecabf"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageD98b0aa4C4eb441bBaf581f35ffecabf(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageD98b0aa4C4eb441bBaf581f35ffecabf`}>
      {props["header"] || <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header8ce4e9deDbeb4f79965231f9d0e53610" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="FooterC0c78bd050694079Ad0cD56f290e685f" {...props} />}
    </div>
  );
}

// id: "2fbb322f-012c-4b27-8329-157a89b8af5b"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "223d2a7a-dcf8-4cde-8567-b6eb368f383f"
export function SecondaryNav2fbb322f012c4b278329157a89b8af5b(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav2fbb322f012c4b278329157a89b8af5b", parentTag)} {...props} />
  );
}

// id: "a859c70d-16a2-4bce-b5da-7fe9aa3670d3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "0e77f299-08bd-4863-b76e-96622286193a"
export function HeaderA859c70d16a24bceB5da7fe9aa3670d3(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="HeaderA859c70d16a24bceB5da7fe9aa3670d3" title="Secondary Page" {...props} />
  );
}

// id: "fb226fda-b16c-4110-89bf-340fb7ab0f21"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "551448c8-7c8c-4384-bd40-f44b59928084"
export const NavMenuSlugFb226fdaB16c411089bf340fb7ab0f21 = "members-primary-nav";

// id: "791f5750-3b13-42df-a797-5207b5bc0f84"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader791f57503b1342dfA7975207b5bc0f84(props: any) {
  return (
    <ShortHeaderD8b340554a1a4fe693d49af4adfad23b parentTag="MembersAreaHeader791f57503b1342dfA7975207b5bc0f84" header-top={<DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f parentTag="HeaderTop74f7039e887142b5A8b1De77da9afd66" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "9eaca8c3-009d-4030-93a3-ca593e9dad87"
// title: ""
// type: :reference
// key: "header"
// parent_id: "54b36f6a-5457-440e-b221-1cd3a2ec7deb"
export function Header9eaca8c3009d403093a3Ca593e9dad87(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header9eaca8c3009d403093a3Ca593e9dad87" title="About Us" {...props} />
  );
}

// id: "4d941d46-4a0b-4ffd-a1f2-f359487d68bb"
// title: ""
// type: :text
// key: "title"
// parent_id: "fe27b1c8-2230-4ce4-85c1-3cb029272a77"
export const Title4d941d464a0b4ffdA1f2F359487d68bb = "Join Us";

// id: "82a01cdc-f9e7-4a43-9dc3-ffb56babb099"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage82a01cdcF9e74a439dc3Ffb56babb099(props: any) {
  return (
    <SecondaryPage0e77f29908bd4863B76e96622286193a parentTag="ResourcesPage82a01cdcF9e74a439dc3Ffb56babb099" header={<SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header47d7eaf03a514430A49603e96b134c5e" title="Resources" />} {...props} />
  );
}

// id: "4652b680-4d05-4c36-ada1-22c6e0fdb4cb"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage4652b6804d054c36Ada122c6e0fdb4cb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage4652b6804d054c36Ada122c6e0fdb4cb`}>
      {props["header"] || <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="HeaderDcfc78f6C9f94324A40047aceaa42d24" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer29bd65e4Dbb54fc7B4a2938b1b996504" {...props} />}
    </div>
  );
}

// id: "74f7039e-8871-42b5-a8b1-de77da9afd66"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "791f5750-3b13-42df-a797-5207b5bc0f84"
export function HeaderTop74f7039e887142b5A8b1De77da9afd66(props: any) {
  return (
    <DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f parentTag="HeaderTop74f7039e887142b5A8b1De77da9afd66" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "510b7bb3-d1a6-4c59-b306-58bab6e6d489"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "e2e647e1-d3e3-4162-aa23-f7cfe3cfec75"
export const ImageUrl510b7bb3D1a64c59B30658bab6e6d489 = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qD9ySvYVxmpqtVP1_regular.png";

// id: "143d2a18-d4e6-4e79-a0e8-c2562c7db9bd"
// title: ""
// type: :reference
// key: "label"
// parent_id: "7d402c8c-e281-4bbb-bd82-5159d5470d33"
export function Label143d2a18D4e64e79A0e8C2562c7db9bd(props: any) {
  return (
    <DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label143d2a18D4e64e79A0e8C2562c7db9bd" {...props} />
  );
}

// id: "eb211619-213c-48f8-8078-2993daa15e86"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyEb211619213c48f880782993daa15e86(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyEb211619213c48f880782993daa15e86 page-body`} />
  );
}

// id: "7fe41fde-6df3-4fb3-9746-2f9ef21f33cf"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title7fe41fde6df34fb397462f9ef21f33cf = "Leveling";

// id: "a4ef762c-b4f1-4394-a6ba-89d211a45873"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "223d2a7a-dcf8-4cde-8567-b6eb368f383f"
export function FooterA4ef762cB4f14394A6ba89d211a45873(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="FooterA4ef762cB4f14394A6ba89d211a45873" {...props} />
  );
}

// id: "19234dbf-a6ad-4913-967c-4dfcf708f2ef"
// title: ""
// type: :reference
// key: "header"
// parent_id: "39deeb88-d528-41d9-8636-f4beb7fc30df"
export function Header19234dbfA6ad4913967c4dfcf708f2ef(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header19234dbfA6ad4913967c4dfcf708f2ef" title="Join Us" {...props} />
  );
}

// id: "6b71bc37-14ec-4b8e-9f6c-7d40fc8e0983"
// title: ""
// type: :text
// key: "href"
// parent_id: "e1c78e5a-8d9c-4b9e-a3d4-7bcbb7a5c74a"
export const Href6b71bc3714ec4b8e9f6c7d40fc8e0983 = "/join";

// id: "6cc68add-d0cb-4cf0-8139-cb3bfd14cb26"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label6cc68addD0cb4cf08139Cb3bfd14cb26(props: any) {
  return (
    <DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label6cc68addD0cb4cf08139Cb3bfd14cb26" {...props} />
  );
}

// id: "e89c60f7-a779-434d-930c-c1303fb7968d"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroE89c60f7A779434d930cC1303fb7968d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroE89c60f7A779434d930cC1303fb7968d hero-banner-container`}>
      <div className="hero-content">
        <h1>
          Oregon’s Premier Nudist Social Group For Gay And Bisexual Men.
        </h1>
        <div className="hero-buttons-container">
          <a href="/join">
            <button className="hero-button-one">
              Become A Member
            </button>
          </a>
          <a href="/events/public">
            <button className="hero-button-two">
              View Upcoming Events
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

// id: "0e59ffee-1c76-494a-9a31-fe35bae09665"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9e11bbd4-23c4-471e-987c-d7fcf039b992"
export function Footer0e59ffee1c76494a9a31Fe35bae09665(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer0e59ffee1c76494a9a31Fe35bae09665" {...props} />
  );
}

// id: "90059ae2-19c3-4c1c-898d-df2bec4414d2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "0e77f299-08bd-4863-b76e-96622286193a"
export function Footer90059ae219c34c1c898dDf2bec4414d2(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer90059ae219c34c1c898dDf2bec4414d2" {...props} />
  );
}

// id: "9ec02571-7689-4385-a514-99eaf6c265a6"
// title: ""
// type: :text
// key: "url"
// parent_id: "6ee00ab5-b5ca-406b-882f-aaec5c1c87c1"
export const Url9ec0257176894385A51499eaf6c265a6 = "/";

// id: "25b8c572-246e-4343-9d39-a11691b1be95"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage25b8c572246e43439d39A11691b1be95(props: any) {
  return (
    <SecondaryPage0e77f29908bd4863B76e96622286193a parentTag="JoinUsPage25b8c572246e43439d39A11691b1be95" header={<SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="HeaderFe27b1c822304ce485c13cb029272a77" title="Join Us" />} {...props} />
  );
}

// id: "a8ae1d7a-e12a-44e7-bfa5-28acf222cfc9"
// title: ""
// type: :text
// key: "title"
// parent_id: "763b18d9-e691-41d3-9fd3-c2d0817ce50a"
export const TitleA8ae1d7aE12a44e7Bfa528acf222cfc9 = "Default Title";

// id: "c47a8f38-0c3c-49cb-8237-2443df52df5d"
// title: ""
// type: :text
// key: "title"
// parent_id: "a859c70d-16a2-4bce-b5da-7fe9aa3670d3"
export const TitleC47a8f380c3c49cb82372443df52df5d = "Secondary Page";

// id: "54b36f6a-5457-440e-b221-1cd3a2ec7deb"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage54b36f6a5457440eB2211cd3a2ec7deb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage54b36f6a5457440eB2211cd3a2ec7deb page`}>
      {props["header"] || <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header9eaca8c3009d403093a3Ca593e9dad87" title="About Us" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer94b70dac4f5b48429ec7B011fde716c5" {...props} />}
    </div>
  );
}

// id: "274a28d1-dd58-476c-8b57-a160ffcca156"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "88b2b9ae-6fce-4164-8575-a2d6febf599f"
export function Navigation274a28d1Dd58476c8b57A160ffcca156(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation274a28d1Dd58476c8b57A160ffcca156", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "52164d37-1407-4dc5-9020-1931475f9e6c"
// title: ""
// type: :reference
// key: "body"
// parent_id: "54b36f6a-5457-440e-b221-1cd3a2ec7deb"
export function Body52164d3714074dc590201931475f9e6c(props: any) {
  return (
    <MarketingBodyEb211619213c48f880782993daa15e86 parentTag="Body52164d3714074dc590201931475f9e6c" postSlug="about-us-posts" {...props} />
  );
}

// id: "b5dbb59f-6840-4abe-8a3f-b856899cf950"
// title: ""
// type: :text
// key: "alt"
// parent_id: "e2e647e1-d3e3-4162-aa23-f7cfe3cfec75"
export const AltB5dbb59f68404abe8a3fB856899cf950 = "logo";

// id: "be80be59-51ec-446e-bffe-855bb94a7d50"
// title: ""
// type: :text
// key: "title"
// parent_id: "19234dbf-a6ad-4913-967c-4dfcf708f2ef"
export const TitleBe80be5951ec446eBffe855bb94a7d50 = "Join Us";

// id: "223d2a7a-dcf8-4cde-8567-b6eb368f383f"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages223d2a7aDcf84cde8567B6eb368f383f(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages223d2a7aDcf84cde8567B6eb368f383f`}>
      {props["header"] || <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header40464e234d8b4124Bc2057137ee8cfc4" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav2fbb322f012c4b278329157a89b8af5b", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="FooterA4ef762cB4f14394A6ba89d211a45873" {...props} />}
    </div>
  );
}

// id: "9e79e968-cb8d-458a-89ec-270dde53b510"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "d8b34055-4a1a-4fe6-93d4-9af4adfad23b"
export function Navigation9e79e968Cb8d458a89ec270dde53b510(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation9e79e968Cb8d458a89ec270dde53b510", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "4f392a08-6045-46e4-88d6-89e2daca4c88"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "c997a96d-30ed-41c5-a084-fe792906df67"
export function Copyright4f392a08604546e488d689e2daca4c88(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright4f392a08604546e488d689e2daca4c88", parentTag)} {...props} />
  );
}

// id: "50e78e56-69dd-45dc-8669-654b4a00f5c0"
// title: ""
// type: :reference
// key: "header"
// parent_id: "50938e30-6916-4ef2-99ea-4371a144f75a"
export function Header50e78e5669dd45dc8669654b4a00f5c0(props: any) {
  return (
    <HomeHeader27f336d65a7242729c534ef2443f7edd parentTag="Header50e78e5669dd45dc8669654b4a00f5c0" {...props} />
  );
}

// id: "e3a0d4ba-5b8d-4f6b-9a27-57cc1b9cf651"
// title: ""
// type: :text
// key: "title"
// parent_id: "184bec94-ba00-4d1d-92c0-0cb82e46512d"
export const TitleE3a0d4ba5b8d4f6b9a2757cc1b9cf651 = "Secondary Marketing Title 2";

// id: "46d593b1-0156-4c1c-aea5-1567285be39e"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href46d593b101564c1cAea51567285be39e = "/";

// id: "cc9a9b9e-bd4a-4b43-8575-53b1b7d77091"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "c997a96d-30ed-41c5-a084-fe792906df67"
export function LinksCc9a9b9eBd4a4b43857553b1b7d77091(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksCc9a9b9eBd4a4b43857553b1b7d77091", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "e1c78e5a-8d9c-4b9e-a3d4-7bcbb7a5c74a"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "e89c60f7-a779-434d-930c-c1303fb7968d"
export function ButtonE1c78e5a8d9c4b9eA3d47bcbb7a5c74a(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonE1c78e5a8d9c4b9eA3d47bcbb7a5c74a", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "d42b9999-f989-4baa-8365-f221fceb5791"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "7843427e-9efa-4ce3-9a51-0837f9fed4fe"
export const PostSlugD42b9999F9894baa8365F221fceb5791 = "home-page-posts2";

// id: "0661e726-cf9e-47bc-b857-776b50dc491c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "274a28d1-dd58-476c-8b57-a160ffcca156"
export const NavMenuSlug0661e726Cf9e47bcB857776b50dc491c = "marketing-primary-nav";

// id: "b61bde94-eabd-4842-b4e9-30624a1da8b4"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterB61bde94Eabd4842B4e930624a1da8b4(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="FooterB61bde94Eabd4842B4e930624a1da8b4" {...props} />
  );
}

// id: "325f00c6-d487-48e9-b798-c7c85c66d1a1"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "50938e30-6916-4ef2-99ea-4371a144f75a"
export function WelcomeBack325f00c6D48748e9B798C7c85c66d1a1(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack325f00c6D48748e9B798C7c85c66d1a1", parentTag)} {...props} />
  );
}

// id: "e5b7fafd-dff3-4845-ac70-7bd89a9b8ee5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "452c3fe0-cc39-4807-90b5-c9f2251bb0cd"
export function HeaderTopE5b7fafdDff34845Ac707bd89a9b8ee5(props: any) {
  return (
    <DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f parentTag="HeaderTopE5b7fafdDff34845Ac707bd89a9b8ee5" {...props} />
  );
}

// id: "dcfc78f6-c9f9-4324-a400-47aceaa42d24"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4652b680-4d05-4c36-ada1-22c6e0fdb4cb"
export function HeaderDcfc78f6C9f94324A40047aceaa42d24(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="HeaderDcfc78f6C9f94324A40047aceaa42d24" title="Leveling" {...props} />
  );
}

// id: "953d3c04-d5d9-487f-abff-60ee6da42b99"
// title: ""
// type: :text
// key: "title"
// parent_id: "dcfc78f6-c9f9-4324-a400-47aceaa42d24"
export const Title953d3c04D5d9487fAbff60ee6da42b99 = "Leveling";

// id: "39deeb88-d528-41d9-8636-f4beb7fc30df"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages39deeb88D52841d98636F4beb7fc30df(props: any) {
  return (
    <SecondaryPage0e77f29908bd4863B76e96622286193a parentTag="SignupPages39deeb88D52841d98636F4beb7fc30df" header={<SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header19234dbfA6ad4913967c4dfcf708f2ef" title="Join Us" />} {...props} />
  );
}

// id: "2c7641f1-f4af-46a5-918b-8f2fe7556c71"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "27f336d6-5a72-4272-9c53-4ef2443f7edd"
export function Hero2c7641f1F4af46a5918b8f2fe7556c71(props: any) {
  return (
    <HomePageHeroE89c60f7A779434d930cC1303fb7968d parentTag="Hero2c7641f1F4af46a5918b8f2fe7556c71" {...props} />
  );
}

// id: "fe27b1c8-2230-4ce4-85c1-3cb029272a77"
// title: ""
// type: :reference
// key: "header"
// parent_id: "25b8c572-246e-4343-9d39-a11691b1be95"
export function HeaderFe27b1c822304ce485c13cb029272a77(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="HeaderFe27b1c822304ce485c13cb029272a77" title="Join Us" {...props} />
  );
}

// id: "9da74dcf-a19e-4349-a5e1-cb7bcf22f77f"
// title: ""
// type: :text
// key: "title"
// parent_id: "452c3fe0-cc39-4807-90b5-c9f2251bb0cd"
export const Title9da74dcfA19e4349A5e1Cb7bcf22f77f = "Secondary Marketing Title 1";

// id: "7aa3b813-9722-49fd-a00c-8847953d0dfd"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "52164d37-1407-4dc5-9020-1931475f9e6c"
export const PostSlug7aa3b813972249fdA00c8847953d0dfd = "about-us-posts";

// id: "763b18d9-e691-41d3-9fd3-c2d0817ce50a"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero763b18d9E69141d39fd3C2d0817ce50a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero763b18d9E69141d39fd3C2d0817ce50a hero`}>
      <div className="title">
        {props["title"] || TitleA8ae1d7aE12a44e7Bfa528acf222cfc9}
      </div>
    </div>
  );
}

// id: "e54b3b6a-a701-4664-b695-d5d394f61e06"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "c997a96d-30ed-41c5-a084-fe792906df67"
export function GroupflowE54b3b6aA7014664B695D5d394f61e06(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowE54b3b6aA7014664B695D5d394f61e06", parentTag)} {...props} />
  );
}

// id: "551448c8-7c8c-4384-bd40-f44b59928084"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9e11bbd4-23c4-471e-987c-d7fcf039b992"
export function Header551448c87c8c4384Bd40F44b59928084(props: any) {
  return (
    <MembersAreaHeader791f57503b1342dfA7975207b5bc0f84 parentTag="Header551448c87c8c4384Bd40F44b59928084" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "cf0e4abc-51a1-4e8a-bd02-db62e35bb88d"
// title: ""
// type: :text
// key: "title"
// parent_id: "c4b4f82c-b057-4318-9f37-bdded1561641"
export const TitleCf0e4abc51a14e8aBd02Db62e35bb88d = null;

// id: "e577b633-51a2-4fed-93a2-b5c5bdf762b3"
// title: ""
// type: :text
// key: "title"
// parent_id: "8ce4e9de-dbeb-4f79-9652-31f9d0e53610"
export const TitleE577b63351a24fed93a2B5c5bdf762b3 = "Public Events";

// id: "d8b34055-4a1a-4fe6-93d4-9af4adfad23b"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderD8b340554a1a4fe693d49af4adfad23b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderD8b340554a1a4fe693d49af4adfad23b site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo7d402c8cE2814bbbBd825159d5470d33", parentTag)} label={<DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label143d2a18D4e64e79A0e8C2562c7db9bd" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation9e79e968Cb8d458a89ec270dde53b510", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "d81244fd-983f-4789-a60e-ec136d804043"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "7843427e-9efa-4ce3-9a51-0837f9fed4fe"
export const ButtonClassD81244fd983f4789A60eEc136d804043 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "35dc48cf-5f23-469a-a67c-962905789fa9"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "50938e30-6916-4ef2-99ea-4371a144f75a"
export function Footer35dc48cf5f23469aA67c962905789fa9(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer35dc48cf5f23469aA67c962905789fa9" {...props} />
  );
}

// id: "dec91014-6040-43ef-9ce8-2998900fd289"
// title: ""
// type: :text
// key: "class"
// parent_id: "274a28d1-dd58-476c-8b57-a160ffcca156"
export const ClassDec91014604043ef9ce82998900fd289 = "navigation";

// id: "27f336d6-5a72-4272-9c53-4ef2443f7edd"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader27f336d65a7242729c534ef2443f7edd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader27f336d65a7242729c534ef2443f7edd site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f parentTag="HeaderTop2203d03c45dc442bB3a0428028d5495d" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroE89c60f7A779434d930cC1303fb7968d parentTag="Hero2c7641f1F4af46a5918b8f2fe7556c71" {...props} />}
    </div>
  );
}

// id: "6ee00ab5-b5ca-406b-882f-aaec5c1c87c1"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "88b2b9ae-6fce-4164-8575-a2d6febf599f"
export function LinkedLogo6ee00ab5B5ca406b882fAaec5c1c87c1(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo6ee00ab5B5ca406b882fAaec5c1c87c1", parentTag)} label={<DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label562e6029B22d4b76B65e873b61cc483a" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "47d7eaf0-3a51-4430-a496-03e96b134c5e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "82a01cdc-f9e7-4a43-9dc3-ffb56babb099"
export function Header47d7eaf03a514430A49603e96b134c5e(props: any) {
  return (
    <SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd parentTag="Header47d7eaf03a514430A49603e96b134c5e" title="Resources" {...props} />
  );
}

// id: "e3c9f885-4cc2-44d1-b95b-f5e4b2324bc6"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "cc9a9b9e-bd4a-4b43-8575-53b1b7d77091"
export const NavMenuSlugE3c9f8854cc244d1B95bF5e4b2324bc6 = "footer-nav";

// id: "88b2b9ae-6fce-4164-8575-a2d6febf599f"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo6ee00ab5B5ca406b882fAaec5c1c87c1", parentTag)} label={<DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75 parentTag="Label562e6029B22d4b76B65e873b61cc483a" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation274a28d1Dd58476c8b57A160ffcca156", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "9b392766-d8e2-40a8-a7ff-9ba190d741ed"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "27f336d6-5a72-4272-9c53-4ef2443f7edd"
export const BackgroundImage9b392766D8e240a8A7ff9ba190d741ed = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "514cc956-29c8-4f05-8c32-3f5d1ff89b29"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "50938e30-6916-4ef2-99ea-4371a144f75a"
export function Quote514cc95629c84f058c323f5d1ff89b29(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote514cc95629c84f058c323f5d1ff89b29", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "29bd65e4-dbb5-4fc7-b4a2-938b1b996504"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4652b680-4d05-4c36-ada1-22c6e0fdb4cb"
export function Footer29bd65e4Dbb54fc7B4a2938b1b996504(props: any) {
  return (
    <DefaultFooterC997a96d30ed41c5A084Fe792906df67 parentTag="Footer29bd65e4Dbb54fc7B4a2938b1b996504" {...props} />
  );
}

// id: "d9ddc96d-8d8b-4713-9357-1e714d459624"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "9e79e968-cb8d-458a-89ec-270dde53b510"
export const NavMenuSlugD9ddc96d8d8b471393571e714d459624 = "members-primary-nav";

// id: "2300ce87-5209-45c5-a25d-7e2b55dd2ce2"
// title: "WhyOmen"
// type: :html
// key: "why-omen"
// parent_id: nil
export function WhyOmen2300ce87520945c5A25d7e2b55dd2ce2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} WhyOmen2300ce87520945c5A25d7e2b55dd2ce2 omen-container`}>
      <h1>
        Why Join OMEN?
      </h1>
      <div className="omen-grid-container">
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 1" />
          <h3>
            Private Group Of Oregon Men Enjoying Naturism
          </h3>
          <p>
            Connect with other friendly naked men via our various organized activities and form great
            camaraderie.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 2" />
          <h3>
            Inclusivity
          </h3>
          <p>
            Open to age-appropriate gay/bi men of all races, colors, religions, physical disabilities, and
            ethnic or national origins.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 3" />
          <h3>
            Monthly Activities And Events
          </h3>
          <p>
            Each month we offer social potlucks, dances, karaoke nights, pool parties, spa nights, camping
            weekends, themed events, and much more.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 4" />
          <h3>
            No Shaming Policy
          </h3>
          <p>
            We&#39;re regular men, not models and whatever your issue may be, someone else will be larger,
            smaller, balder, so just be yourself and join us!
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 5" />
          <h3>
            Exclusive Discounts
          </h3>
          <p>
            Save money on spa nights, food, and beverages from our recommended partners and get access to
            exclusive offers.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 6" />
          <h3>
            Member Driven Organization
          </h3>
          <p>
            We are not a business venture. Most of our gatherings are in private residences and camping on
            private, member-owned land.
          </p>
        </div>
      </div>
    </div>
  );
}

// id: "a2e03790-3b22-4331-8a63-55228851b1a2"
// title: ""
// type: :reference
// key: "why-omen"
// parent_id: "eb211619-213c-48f8-8078-2993daa15e86"
export function WhyOmenA2e037903b2243318a6355228851b1a2(props: any) {
  return (
    <WhyOmenA2e037903b2243318a6355228851b1a2 parentTag="WhyOmenA2e037903b2243318a6355228851b1a2" {...props} />
  );
}

const Components = {
  Body8a471573Af6b46248d5421a9dac12a01,
  DefaultFooterC997a96d30ed41c5A084Fe792906df67,
  OurStoryF01cdb47543345c399ef5d9a33a2989a,
  JoinOmen053e6adb3cd1428bB6890b096cb29716,
  OmenImageGallery03ef9469206b419c8f82Ea74b0ef5d8a,
  HomePage50938e3069164ef299ea4371a144f75a,
  MoreQuestionsC71fc2963a8e4cd2A4832f09d8276274,
  AdminPagesD924ad7cE14e4b93A665C9e008a77f50,
  Header923c5d7163a1475cA43e528a65c1b948,
  Logo89080c8d27da4b37Bf9b633028d554bd,
  LinkedLogo7d402c8cE2814bbbBd825159d5470d33,
  NavMenuSlug9a7762a529b14c70Aa02B4ace1c41133,
  Title9f20e7248b184938A1ca602176ef20ee,
  MembersAreaDefault9e11bbd423c4471e987cD7fcf039b992,
  MessagePages9c9f124361294cc3Bc47D33471b1171e,
  Hero184bec94Ba004d1d92c00cb82e46512d,
  Header8ce4e9deDbeb4f79965231f9d0e53610,
  Label86e223575e29485080f497bc8454e196,
  DefaultLogoE2e647e1D3e34162Aa23F7cfe3cfec75,
  SecondaryMarketingHeader452c3fe0Cc39480790b5C9f2251bb0cd,
  Footer94b70dac4f5b48429ec7B011fde716c5,
  FooterC0c78bd050694079Ad0cD56f290e685f,
  ContentSlug29573296D3764a81B1ceB1f5eeb70391,
  Title2fc1e3a1000f4c64906404bf24e3fcee,
  DefaultHeaderC4b4f82cB05743189f37Bdded1561641,
  Header719db0e3Df624f8b88080b819876ba3b,
  HeaderTop2203d03c45dc442bB3a0428028d5495d,
  Label562e6029B22d4b76B65e873b61cc483a,
  SecondaryPage0e77f29908bd4863B76e96622286193a,
  MarketingCards7843427e9efa4ce39a510837f9fed4fe,
  NavMenuSlugE2c42bb37c5746d9Aca94565b08e30f7,
  Header40464e234d8b4124Bc2057137ee8cfc4,
  Class19dc2a7a24234dd8B66b475a879cf63a,
  PageQuote79be24efA98a4986Bd8883b2de8a3c78,
  PublicEventsPageD98b0aa4C4eb441bBaf581f35ffecabf,
  SecondaryNav2fbb322f012c4b278329157a89b8af5b,
  HeaderA859c70d16a24bceB5da7fe9aa3670d3,
  NavMenuSlugFb226fdaB16c411089bf340fb7ab0f21,
  MembersAreaHeader791f57503b1342dfA7975207b5bc0f84,
  Header9eaca8c3009d403093a3Ca593e9dad87,
  Title4d941d464a0b4ffdA1f2F359487d68bb,
  ResourcesPage82a01cdcF9e74a439dc3Ffb56babb099,
  AboutLevelingPage4652b6804d054c36Ada122c6e0fdb4cb,
  HeaderTop74f7039e887142b5A8b1De77da9afd66,
  ImageUrl510b7bb3D1a64c59B30658bab6e6d489,
  Label143d2a18D4e64e79A0e8C2562c7db9bd,
  MarketingBodyEb211619213c48f880782993daa15e86,
  Title7fe41fde6df34fb397462f9ef21f33cf,
  FooterA4ef762cB4f14394A6ba89d211a45873,
  Header19234dbfA6ad4913967c4dfcf708f2ef,
  Href6b71bc3714ec4b8e9f6c7d40fc8e0983,
  Label6cc68addD0cb4cf08139Cb3bfd14cb26,
  HomePageHeroE89c60f7A779434d930cC1303fb7968d,
  Footer0e59ffee1c76494a9a31Fe35bae09665,
  Footer90059ae219c34c1c898dDf2bec4414d2,
  Url9ec0257176894385A51499eaf6c265a6,
  JoinUsPage25b8c572246e43439d39A11691b1be95,
  TitleA8ae1d7aE12a44e7Bfa528acf222cfc9,
  TitleC47a8f380c3c49cb82372443df52df5d,
  AboutUsPage54b36f6a5457440eB2211cd3a2ec7deb,
  Navigation274a28d1Dd58476c8b57A160ffcca156,
  Body52164d3714074dc590201931475f9e6c,
  AltB5dbb59f68404abe8a3fB856899cf950,
  TitleBe80be5951ec446eBffe855bb94a7d50,
  MemberPages223d2a7aDcf84cde8567B6eb368f383f,
  Navigation9e79e968Cb8d458a89ec270dde53b510,
  Copyright4f392a08604546e488d689e2daca4c88,
  Header50e78e5669dd45dc8669654b4a00f5c0,
  TitleE3a0d4ba5b8d4f6b9a2757cc1b9cf651,
  Href46d593b101564c1cAea51567285be39e,
  LinksCc9a9b9eBd4a4b43857553b1b7d77091,
  ButtonE1c78e5a8d9c4b9eA3d47bcbb7a5c74a,
  PostSlugD42b9999F9894baa8365F221fceb5791,
  NavMenuSlug0661e726Cf9e47bcB857776b50dc491c,
  FooterB61bde94Eabd4842B4e930624a1da8b4,
  WelcomeBack325f00c6D48748e9B798C7c85c66d1a1,
  HeaderTopE5b7fafdDff34845Ac707bd89a9b8ee5,
  HeaderDcfc78f6C9f94324A40047aceaa42d24,
  Title953d3c04D5d9487fAbff60ee6da42b99,
  SignupPages39deeb88D52841d98636F4beb7fc30df,
  Hero2c7641f1F4af46a5918b8f2fe7556c71,
  HeaderFe27b1c822304ce485c13cb029272a77,
  Title9da74dcfA19e4349A5e1Cb7bcf22f77f,
  PostSlug7aa3b813972249fdA00c8847953d0dfd,
  SecondaryMarketingHero763b18d9E69141d39fd3C2d0817ce50a,
  GroupflowE54b3b6aA7014664B695D5d394f61e06,
  Header551448c87c8c4384Bd40F44b59928084,
  TitleCf0e4abc51a14e8aBd02Db62e35bb88d,
  TitleE577b63351a24fed93a2B5c5bdf762b3,
  ShortHeaderD8b340554a1a4fe693d49af4adfad23b,
  ButtonClassD81244fd983f4789A60eEc136d804043,
  Footer35dc48cf5f23469aA67c962905789fa9,
  ClassDec91014604043ef9ce82998900fd289,
  HomeHeader27f336d65a7242729c534ef2443f7edd,
  LinkedLogo6ee00ab5B5ca406b882fAaec5c1c87c1,
  Header47d7eaf03a514430A49603e96b134c5e,
  NavMenuSlugE3c9f8854cc244d1B95bF5e4b2324bc6,
  DefaultHeaderTop88b2b9ae6fce41648575A2d6febf599f,
  BackgroundImage9b392766D8e240a8A7ff9ba190d741ed,
  Quote514cc95629c84f058c323f5d1ff89b29,
  Footer29bd65e4Dbb54fc7B4a2938b1b996504,
  NavMenuSlugD9ddc96d8d8b471393571e714d459624,
  WhyOmen2300ce87520945c5A25d7e2b55dd2ce2,
  WhyOmenA2e037903b2243318a6355228851b1a2
}

export default Components;