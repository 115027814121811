const ROUTES = {
  "/": {
    "name": "HomePage50938e3069164ef299ea4371a144f75a",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesD924ad7cE14e4b93A665C9e008a77f50",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage54b36f6a5457440eB2211cd3a2ec7deb",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage4652b6804d054c36Ada122c6e0fdb4cb",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageD98b0aa4C4eb441bBaf581f35ffecabf",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage25b8c572246e43439d39A11691b1be95",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages223d2a7aDcf84cde8567B6eb368f383f",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages9c9f124361294cc3Bc47D33471b1171e",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage82a01cdcF9e74a439dc3Ffb56babb099",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages39deeb88D52841d98636F4beb7fc30df",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;